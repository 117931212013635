

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Palatino', 'Georgia', 'Times New Roman',
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'Franklin Gothic Medium', 'Helvetica',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h2 {
  margin: 0;
  font-size: 2.4em;
  letter-spacing: 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'Franklin Gothic Medium', 'Helvetica',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  margin: 0;
  font-size: 1.4em;
  line-height: 1.4em;
  font-family: -apple-system, BlinkMacSystemFont, 'Palatino', 'Georgia', 'Times New Roman',
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Palatino', 'Georgia', 'Times New Roman',
    serif;
  line-height: 1.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
