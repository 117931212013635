
body {
  margin: 0px;
  padding: 0px;
  font-size: 12pt;
  color: #ffffff;
}


a {
  text-decoration: none;
  color: #ffffff;
}
  a:hover {
      text-decoration: none;
  }


/*********************************************************************************/
/* NavMenu                                                                       */
/*********************************************************************************/

  .nav {
    width: auto;
    padding-bottom: 18px;
    margin-top: 46px;
    position: relative;
    height: 100px;
    transition-duration: 0.4s;
}

    .nav.active { 
        height: 80px;
        padding-bottom: 28px;
        margin-top: -5px;
        font-size: .85em;
        transition-duration: 0.4s;
        border-bottom: 4px solid rgba(255, 255, 255, 0.5)
    }

    #header-wrapper {
      transition: 0.4s;
      background: #112749;
      position: fixed;
      z-index: 400;
      width: 100%;
      top: 0;
  }
  
  #header {
      position: relative;
      height: 100px;
  }


/*********************************************************************************/
/* Contact Top                                                                   */
/*********************************************************************************/

.nav2 {
  width: auto;
  position: relative;
  z-index: 500;
  background: #b52626;
  height: 45px;
  transition-duration: 0.6s;
}

  .nav2.active { 
      display: none;
      transition-duration: 0.6s;
  }

  #contact-top {
    background: #b52626;
    height: 53px;
    text-align: center;
    margin-top: -1.3em;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 2px;
    }
  

    #contact-top2 ul {
      clear: both;
      text-align: left;
      margin-left: 20px;
    }
    
    #contact-top2 li {
      clear: both;
      line-height: 22px;
      padding-right: 35px;
      padding-top: 15px;
      display: inline-block;
    }  
  

/*********************************************************************************/
/* Menu                                                                          */
/*********************************************************************************/

#menu {
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'Franklin Gothic Medium', 'Helvetica',
  sans-serif;
  position: absolute;
  right: 0;
  top: 1em;
}

  #menu ul {
      display: inline-block;
  }

  #menu li {
      display: block;
      float: left;
      text-align: center;
      position: relative;
      width: auto;
      line-height: 60px;
  }

      #menu li a, #menu li span {
          display: inline-block;
          margin-left: 0px;
          padding: 0em 1.3em;
          letter-spacing: 0.10em;
          text-decoration: none;
          font-size: 15px;
          text-transform: uppercase;
          color: rgb(180, 180, 180);
          transition-duration: 0.4s;
      }

      #menu li:hover a, #menu li.active a, #menu li.active span {
          color: white;
      }

      #menu li.current_page_item a {
          color: #FFF !important;
      }

      #menu-item {
        font-size: 20px;
      } 

      #submenu {
        padding: 0;
        left: -40px !important;
        opacity: 0;
        position: absolute;
        visibility: hidden;
        z-index: 1;
      }
          li:hover ul#submenu {
            left: 0;
            margin: 0px;
            opacity: 1;
            top: 65px;
            visibility: visible;
          }

          #submenu li {
            left: 0;
            float: left;
            width: 100%;
            background:#112749;
            line-height: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
          }
            #submenu li:hover {
              background: #2b3c55;
            }

            .first-item {
              padding-top: 15px !important;
            }
              .last-item {
                padding-bottom: 20px !important;
              }

      .menu-book {
        font-size: 1.1em;
        background-color: #b52626;
        transition-duration: 0.4s;
        padding: 0px 23px;
        color: #ffffff;
        margin-right: 55px;
      } 

            .menu-book:link {
                background-color: #b52626;
            }

            .menu-book:visited {
                background-color: #b52626;
            }

            .menu-book:hover {
                background-color: #8b0f0f;
                color: #b2b2b2;
            }
      
      #hero-button {
        margin: auto;
        margin-top: 30px;
        padding: 0px 0px;
        max-width: 190px;
        font-size: 20px;
      }


  /*********************************************************************************/
  /* Logo                                                                          */
  /*********************************************************************************/
  
  #logo {
      position: absolute;
      top: 1.2em;
      left: 0;
      width: 100%;
      text-align: left;
      margin-left: 55px;
  }
  
          #logo-ham {
            position: absolute;
            height: 20px;
            top: 14px;
            left: 0;
            text-align: left;
            margin-left: 21px;
        }
  
      #logo h1 {
          display: inline-block;
          font-size: 2.6em;
          text-transform: uppercase;
          font-weight: bold;
          color: #ffffff;
          padding-right: 0.50em;
      }
  
      #logo span {
          position: absolute;
          top: 1.4em;
          display: inline-block;
          padding-left: 1.50em;
          letter-spacing: 0.10em;
          text-transform: uppercase;
          font-size: 0.90em;
      }
  
      #logo a {
          text-decoration: none;
          color: #ffffff;
      }
    
    #logo-main {
        height: 85px;
    }
  
    #logo-main-ham {
      height: 55px;
  }

    #logo.active {
      height: 30px;
    }

    

/*********************************************************************************/
/* Home Page                                                                     */
/*********************************************************************************/
  
.home {
  background-image: url('./images/parchment.jpg');
  background-repeat: repeat;
  height: 1000px;
}

.page {
  background-image: url('./images/parchment.jpg');
  background-repeat: repeat;
}

.parchment-box {
  margin: 0% 15%;
  padding-top: 50px;
  padding-bottom: 40px;
  color: #000;
}
      .blue-box {
        background-color: #112749;
        padding: 0% 15%;
        padding-top: 48px;
        padding-bottom: 40px;
        color: #ffffff;
      }
          .red-box {
            background-color: #b52626;
            padding: 0% 15%;
            padding-top: 40px;
            padding-bottom: 50px;
            color: #ffffff;
          }

.blue-boxes-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  gap: 25px;
}

    .blue-boxes {
      background: #081b38;
      width: 100%;
    }

    .blue-box-text {
      padding: 30px 30px;
      padding-bottom: 60px;
      display: flex !important;
      flex-wrap: wrap;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .blue-box-text h1 {
      font-size: 28px;
      line-height: 32px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
    }

    .blue-box-text p {
      font-size: 21px;
      line-height: 25px;
      color: #b2b2b2;
      text-align: center;
      margin-bottom: -40px;
    }

    #blue-box-button {
      margin: auto;
      font-size: 20px;
      padding: 10px 0px;
      margin-top: 30px;
      margin-bottom: 60px;
      max-width: 180px;
    }
    
    .pic-wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      flex-direction: row;
      gap: 25px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    #pic-home {
      width: 100%;
    }

    #column-pic-wrap {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    #column-pic {
      width: 100%;
    }

    #cartoon-wrap {
      display: flex;
      justify-content: center;
    }
      #cartoon {
        width: 70%;
      }

#all-caps {
  text-transform: uppercase;
}

#all-caps-padding {
  text-transform: uppercase;
  padding-bottom: 20px;
}

/*********************************************************************************/
/* Slider                                                                        */
/*********************************************************************************/

.hero-wrapper {
  position: relative;
}

.hero-text-box {
  position: absolute;
  z-index: 200;
  top: 0;
  width: 100%;
}

.hero-text {
  margin-left: 20%;
  margin-right: 20%;
  font-size: 23px;
  line-height: 58px;
  text-align: center;
}
    #hero-title {
      padding-top: 27%;
    }

.slide-text {
  z-index: 500;
  text-align: center;
}

.each-slide {
  display: flex;
  width: 100%;
  height: 550px;
}

.each-slide>div {
  width: 100%;
}

.each-slide>div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-slide p {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.hero-slider {
  background-color: #081b38;
  padding-top: 121px;
}

.gallery-pic {
  display: flex;
  width: 100%;
}

.react-slideshow-container .nav {
  padding-bottom: 0;
}

#promo {
  padding-top: 20px;
  max-width: 325px;
}

/*********************************************************************************/
/* Parallax                                                                      */
/*********************************************************************************/


.parallax-tour {
  background-color: #112749;
  background-image: url("images/tour-group.jpg");
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  margin-bottom: -18px;
}

.parallax-about {
  background-color: #112749;
  background-image: url("images/about-battle.jpg");
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  margin-bottom: -18px;
}

#parallax-box {
  text-align: center;
} 

.overlay {
  background-color: rgba(12, 35, 69, 0.7);
  height: 100%;
  overflow: hidden;
  z-index: 2;
}

.container {
  margin: 0px 40px;
  padding-top: 220px;
  margin-top: 15px;
  width: auto;
  overflow: hidden;
  text-align: center;
}

.subtitle {
  margin: 0% 20%; 
  padding-top: 28px;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/*********************************************************************************/
/* About                                                                         */
/*********************************************************************************/
  
.picture-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0px;
}

.picture {
  width: 110%;
}

  #bunker-hill {
    max-width: 100%;
  }

  #cookware {
    max-width: 100%;
  }

.right-column-text {
  padding-left: 40px;
}

.left-column-text {
  padding-right: 40px;
}

#text-middle {
  align-items: center;
}

  .subheading {
    font-size: 1.3em;
  }

  .subheading2 {
    font-size: 30px;
  }

  .subheading3 {
    text-transform: uppercase;
    font-size: 22px;
  }

  .subheading4 {
    font-size: 16px;
  }

#top-padding {
  padding-top: 15px;
  margin-bottom: -10px;
}

#contact-us p {
  line-height: 12px;
}


/*********************************************************************************/
/* Tour Details                                                                  */
/*********************************************************************************/

#wide-box {
  width: 80%;
}

#pickup-title {
  line-height: 37px;
  padding-bottom: 8px;
}

#pickup-map {
  width: 70%;
  margin-right: -20px;
}

.pickup-details {
  color: #d6d6d6;
  padding-left: 20px;
}

.map-button {
  font-size: .95em;
  background-color: #b52626;
  transition-duration: 0.4s;
  padding: 10px 10px;
  padding-left: 21px;
  color: #ffffff;
  max-width: 127px;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 45px;
} 
          
      .map-button:link {
          background-color: #b52626;
      }

      .map-button:visited {
          background-color: #b52626;
      }

      .map-button:hover {
          background-color: #8b0f0f;
          color: #e0e0e0;
      }

  #details-heading {
    padding-top: 20px;
  }

  .details-list li {
    padding-bottom: 7px;
  }


/*********************************************************************************/
/* Itineraries                                                                   */
/*********************************************************************************/
#itin-page {
  padding-top: 180px;
  padding-bottom: 100px;
}

.itin-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px; 
}

.itin-page-box {
  display: flex;
  background: #081b38;
  color: #e0e0e0;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.itin-page-box h1 {
  text-align: center;
}

/*********************************************************************************/

#beige {
  color: #e8dcc3;
}

.itin-title {
  padding-top: 105px;
  margin-bottom: -11px;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  line-height: 44px;
  color: #081b38;
}
    .itin-description-title {
      padding-bottom: 6px;
      text-transform: uppercase;
      text-align: center;
      font-size: 35px;
      line-height: 38px;
      color: #000000;
    }

.itin-subtitle {
  font-size: 20px;
}

.right-column-list {
  padding-left: 45px;
}

#minuteman {
  width: 45%;
}

.map-box {
  background-image: url('./images/background-map.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
    .map-content {
      margin: 0% 15%;
      padding-top: 50px;
      padding-bottom: 40px;
      color: #e8dcc3;
      display: flex;
      justify-content: space-around;
      gap: 20px;
    }

  
.lighthouse-box {
  background-image: url('./images/lighthouse-panoramic.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
    .lighthouse-content {
      display: flex;
      justify-content: center;
      color: #704645;
      text-transform: uppercase;
      transition: .8s ease;
      text-align: center;
      padding: 0px 25px;
    }

    .lighthouse-content a {
      font-size: 25px;
      line-height: 55px;
      transition: .8s ease;
      padding: 0px 15px;
    }

            .lighthouse-content a:link {
              color: #eecdd8;
          }
            .lighthouse-content a:visited {
              color: #eecdd8;
            }
            .lighthouse-content a:hover {
              color: #704645;
            }


.drawn-box {
  background-image: url('./images/background-plymouth.jpg');
  background-position: center;
  background-size: cover;

}
    .drawn-content {
      margin: 0% 15%;
      padding-top: 50px;
      padding-bottom: 40px;
      color: #000;
    }

hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #e8dcc3;
  margin: 0;
  padding: 0;
}

.timing {
  padding-top: 40px;
  font-size: 1.3em;
  text-align: center;
}


#padding-bottom {
  margin-bottom: 30px;
}

#battleroad-map {
  margin: auto;
  width: 100%;
  padding-top: 40px;
}

.justify {
  text-align: justify;
}

.center {
  display: flex;
  justify-content: center;
}

#margin-top {
  margin-top: 10px;
}

.details-button {
  text-align: center;
  font-size: .95em;
  background-color: #081b38;
  transition-duration: 0.4s;
  padding: 16px 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ffffff;
  max-width: 200px;
} 
          
      .details-button:link {
          background-color: #081b38;
      }

      .details-button:visited {
          background-color: #081b38;
      }

      .details-button:hover {
          background-color: #233f69;
          color: #e0e0e0;
      }

    

/*********************************************************************************/
/* Footer                                                                        */
/*********************************************************************************/

#footer-wrapper {
  overflow: hidden;
  background: #ffffff;
  padding-top: 40px;
  margin-bottom: 40px;
}

.table-footer {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  }

#brand {
  width: 55%;
  }

#brand2 {
  width: 65%;
  }

#brand3 {
  width: 55%;
}

#brand4 {
  width: 75%;
  }

  #table-footer2 {
    padding-left: 15%;
    padding-top: 10px;
  }

  #table-footer3 {
    padding-top: 10px;
    padding-left: 9%;
  }

#footer-wrapper2 {
  overflow: hidden;
  background: #b52626;
  text-align: center;
  margin: -1.3em 0em;
  padding-bottom: 5px;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 2px;
  }

      .row {
        display: inline-block;
      }

      #footer2 ul {
          display: inline-block;
      }

      #footer2 li {
          display: block;
          float: left;
          text-align: center;
          line-height: 60px;
          padding-right: 35px;
      }

      #social {
        margin-bottom: -5px;
        height: 25px;
      }

#footer-wrapper3 {
  overflow: hidden;
  padding: 1.05em 0em 1.6em 0em;
  background: #112749;
  text-align: center;
}

#footer-wrapper3 p {
  overflow: hidden;
  color: rgb(219, 219, 219);
  margin-bottom: -7px;
}

#footer-wrapper3 a {
  overflow: hidden;
  color: rgb(219, 219, 219);
  text-decoration: underline;
}




/*********************************************************************************/
/* Small Screen                                                                  */
/*********************************************************************************/


@media screen and (min-width: 1015px) {

  #hide-comp {
    display: none;
  }

}

@media screen and (min-width: 875px) {

  #column-pic-wrap {
    display: none;
  }
}

@media screen and (max-width: 1015px) {

  #hide-mobile {
    display: none;
  }

  #header-wrapper {
    display: none;
  }

      .nav {
        display: none;
        height: 0;
      }

      .nav2 {
        display: none;
        height: 0;
      }

      #contact-top {
        display: none;
      }

.hero-slider {
      padding-top: 0px;
    }

    #cartoon {
      width: 100%;
    }

  .subheading2 {
      line-height: 40px;
    }

/*****************************************************/
/* Hamburger                                         */
/*****************************************************/

.off-screen-menu {
  z-index: 1000;
  background: #112749;
  color: #b2b2b2;
  margin: -18px 0px;
  width: 100%;
  max-width: 330px;
  position: fixed;
  top: 0;
  margin-top: 80px;
  right: -450px;
  display: flex;
  flex-direction: column;
  transition: .5s ease;
  height: 100%;
}

.off-screen-menu ul {
  list-style-type: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'Franklin Gothic Medium', 'Helvetica',
  sans-serif;
  font-weight: bold;
  color: #b2b2b2;
  font-size: 28px;
  line-height: 60px;
}

.off-screen-menu.active {
  right: 0;
}

nav {
  padding: 1rem;
  display: flex;
  background-color: #112749;
  padding-right: 60px;
  }
  
  .ham-menu {
  height: 50px;
  width: 50px;
  margin-left: auto;
  position: relative;
  }
  
  .ham-menu span {
  height: 5px;
  width: 100%;
  background-color: #d6d6d6;
  border-radius: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: .3s ease;
}

.ham-menu span:nth-child(1) {
top: 25%;
}

.ham-menu span:nth-child(3) {
top: 75%;
}

.ham-menu.active span:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  margin-top: 4px;
}

  .ham-menu.active span:nth-child(2) {
    opacity: 0;
  }

  .ham-menu.active span:nth-child(3) {  
    top: 50%;
    transform: translate(-50%, 50%) rotate(-45deg);
}


/***********************************************************/

#hero-title {
  padding-top: 17%;
}
  .hero-text {
    margin-left: 15%;
    margin-right: 15%;
    font-size: 22px;
    line-height: 56px;
  }  
  .each-slide {
    display: flex;
    width: 100%;
    height: 510px;
  }

  .parallax-tour {
    height: 320px;
    margin-top: -10px;
  }
          
          .parallax-about {
            height: 300px;
            margin-top: -10px;
          }
  
  .container {
    margin: 0px 40px;
    padding-top: 80px;
  }
  
  .subtitle {
    margin: 0% 5%;
    font-size: .95em;
    padding-top: 20px;
  }

  .parchment-box {
    margin: 0% 10%;
    padding-top: 55px;
  }
  
      .blue-box {
        padding: 0% 10%;
        padding-top: 55px;
        padding-bottom: 55px;
      }

      .red-box {
        padding: 0% 10%;
        padding-top: 55px;
        padding-bottom: 55px;
      }

      .itin-title {
        padding-top: 0px;
        margin-top: -15px;
      }

  .picture-text-container {
    align-items: flex-start;  
  }

  .picture {
    min-width: 220px;
  }

  .blue-box-text p {
    font-size: 17px;
    line-height: 22px;
  }

  #blue-box-button {
    font-size: 17px;
  }

  .right-column-text {
    margin-top: -16px;
    padding-left: 30px;
  }
      
    .left-column-text {
      margin-top: -18px;
      padding-top: 0px;
      padding-bottom: 12px;
      padding-right: 25px;
    }

    .subheading {
      font-size: 1.1em;
    }

    #pickup-title {
      line-height: 35px;
      padding-bottom: 0px;
    }

  .map-content {
      flex-direction: column;
      justify-content: flex-start;
      gap: 0px;
  }

  .table-footer {
    width: 90%;
    }

  #footer-wrapper2 {
    font-size: 7px;
    text-transform: uppercase;
    letter-spacing: 1px;
    }
  
  #footer2 ul {
    display: block;
    clear: both;
    margin: 2em 0em;
  }
  
  #footer2 li {
    float: none;
    clear: both;
    display: block;
    line-height: 33px;
    padding-right: 35px;
  }  

  #footer-wrapper3 {
    font-size: 13px;
  }

  #brand4 {
    padding-top: 20px;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  #itin-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .itin-page-wrapper {
    gap: 20px; 
  }
  
  .itin-page-box {
    padding: 10px 10px;
    font-size: 10px;
  }
  
  .subheading4 {
    padding-top: 17px;
  }

}

/*********************************************************************************/
/* TABLET-SMALL                                                                  */
/*********************************************************************************/


@media screen and (max-width: 875px) {

  .blue-boxes-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }

  .blue-box-text p {
    margin-bottom: 0px;
  }

  #blue-box-button {
    margin: auto;
    font-size: 20px;
    padding: 10px 0px;
    margin-top: 30px;
    margin-bottom: -22px;
    max-width: 190px;
  }

nav {
  padding-right: 50px;
  }


  #hero-title {
    padding-top: 20%;
  }
    .hero-text {
      font-size: 20px;
      line-height: 48px;
    }  

    .lighthouse-content a {
      font-size: 18px;
      line-height: 45px;
      transition: .8s ease;
      padding: 0px 15px;
    }

            .lighthouse-content a:link {
              color: #f2e3e8;
          }
            .lighthouse-content a:visited {
              color: #f2e3e8;
            }
            .lighthouse-content a:hover {
              color: #704645;
            }


}

/*********************************************************************************/
/* PHONE                                                                         */
/*********************************************************************************/


@media screen and (max-width: 630px) {

  #center-phone {
    display: flex;
    justify-content: center;
    text-align: center;
  }

.parallax-tour {
  margin-top: 0px;
}
        
        .parallax-about {
          margin-top: 0px;
        }

        
#hero-title {
  padding-top: 18%;
}
  .hero-text {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 17px;
    line-height: 40px;
  }  
  .each-slide {
    display: flex;
    width: 100%;
    height: 500px;
  }
 
#promo {
  padding-top: 20px;
  max-width: 300px;
}

.picture-text-container {
  display: flex;
  flex-direction: column;
}

.right-column-text {
  margin-top: 0px;
  padding-left: 0px;
  margin-right: -22px;
}
      
.left-column-text {
  padding-top: 7px;
  padding-bottom: 12px;
  padding-right: 0px;
  margin-right: -22px;
}

.right-column-text#centered {
  padding-left: 11px;
}

#wide-box {
  width: 100%;
}

#pickup-map {
  width: 100%;
  margin-right: 0px;
  padding-top: 20px;
}

#wider {
  margin: 0px -35px;
}

.subheading4 {
  padding-top: 17px;
}

#bus {
padding-left: 12px;
}

#google-map {
 display: none; 
}

.pickup-details {
  padding-left: 0px;
}

.map-button {
  padding: 10px 0px;
  max-width: none;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
} 
          
      .map-button:link {
          background-color: #b52626;
      }

      .map-button:visited {
          background-color: #b52626;
      }

      .map-button:hover {
          background-color: #8b0f0f;
          color: #e0e0e0;
      }

.itin-title {
  font-size: 30px;
  line-height: 34px;
  }
  .itin-description-title {
    padding-bottom: 0px;
    font-size: 26px;
    line-height: 28px;
  }

  #minuteman {
    display: none;
  }

  .subheading3 {
    margin-block-start: 0px;
    margin-block-start: 0px;
    margin: 0px -20px;
    padding: 0px;
  }

  .map-content {
    margin: 0% 9%;
    padding-top: 40px;
    padding-bottom: 30px;
  }
    .drawn-content {
      margin: 0% 8%;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    nav {
      padding-right: 40px;
      }

}


.parallax-tour {
  margin-top: -10px;
}
        
        .parallax-about {
          margin-top: -10px;
        }

/*********************************************************************************/
/* SLIM PHONE                                                                    */
/*********************************************************************************/


@media screen and (max-width: 395px) {

  .parchment-box {

    margin-left: 8%;
    margin-right: 8%;
  }

  .blue-box {
    padding-left: 8%;
    padding-right: 8%;
  }
  
  .red-box {
    padding-left: 8%;
    padding-right: 8%;
  }

  
#hero-title {
  padding-top: 18%;
}
  .hero-text {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 15px;
    line-height: 37px;
  }  
  .each-slide {
    display: flex;
    width: 100%;
    height: 420px;
  }  
  #promo {
    padding-top: 15px;
    max-width: 275px;
  }

  .parallax-tour {
    height: 420px;
    margin-top: 0px;
  }
          
          .parallax-about {
            height: 360px;
            margin-top: 0px;
          }

      .right-column-list {
        margin-top: -20px;
        padding-left: 20px;
      }

      #wider {
        margin-left: -10px;
        margin-right: -12px;
      }

#footer-email {
  font-size: 12px;
  padding-bottom: 5px;
}

}